<template>
  <b-card>
    <validation-observer v-slot="{ handleSubmit }">
      <b-form
        class="mt-2"
        @submit.prevent
      >
        <fieldset :disabled="isDatePassed">
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  label="Name"
                  label-for="event-name"
                  class="mb-2"
                >
                  <b-form-input
                    id="event-name"
                    v-model="eventData.activity_name"
                    placeholder="Jomaa, Taraweeh, Eid Adha, Eid Fitr, etc"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="Start Date"
                    rules="required"
                  >
                    <b-form-group
                      label="Start Date"
                      label-for="start_date"
                      class="mb-2"
                    >
                      <flat-pickr
                        v-model="eventData.start_time"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i',
                        }"
                        @input="
                          validationContext.validate(eventData.start_time)
                        "
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="End Date"
                    rules="required"
                  >
                    <b-form-group
                      label="End Date"
                      label-for="end_date"
                      class="mb-2"
                    >
                      <flat-pickr
                        v-model="eventData.end_time"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i',
                          minDate: $moment(eventData.start_time).add({minutes:1}).format('YYYY-MM-DD HH:mm'),
                          maxDate: maxEndDate,
                        }"
                        @input="validationContext.validate(eventData.end_time)"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Presence Type"
                rules="required"
              >
                <b-form-group
                  label="Presence Type"
                  label-for="presence-type"
                >
                  <b-form-select
                    id="presence-type"
                    v-model="eventData.presence_type"
                    :options="presenceTypeOptions"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="2">
              <date-time
                id="activate-time"
                :datetime.sync="eventData.publish_at"
                name="Activate Booking Time"
                rules="required"
                :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today', maxDate:eventData.start_time}"
              />
            </b-col>

            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="General Notes"
                rules=""
              >
                <b-form-group
                  label="General Notes"
                  label-for="notes"
                  class="mb-2"
                >
                  <b-form-textarea
                    id="notes"
                    v-model="eventData.activity_description"
                    placeholder="General notes for this event"
                    rows="3"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </fieldset>
        <back class="mr-1" />

        <submit-button
          v-if="!isDatePassed"
          :handle-submit="handleSubmit"
          :submit="editEvent"
        />
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/FormInputs/Back.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import DateTime from '../../common/FormInputs/DateTime.vue'

export default {
  name: 'EditEvent',
  components: {
    flatPickr,
    Back,
    SubmitButton,
    DateTime,
  },
  props: {
    eventData: { type: Object, default: null },
    isDatePassed: { type: Boolean, default: false },
  },
  data() {
    return {
      maxEndDate: '',
      presenceTypeOptions: [
        { text: 'In Person', value: 'offline' },
        { text: 'Online', value: 'online' },
        { text: 'Both', value: 'both' },
      ],
    }
  },
  setup() {
    const { getValidationState } = formValidation()

    const { successfulOperationAlert } = handleAlerts()
    return {
      successfulOperationAlert,

      getValidationState,
    }
  },
  created() {
    this.getMaxSessionEndDate()
  },
  methods: {
    editEvent() {
      return this.$activities
        .post(
          `internalops/prayer-activities/${this.$router.currentRoute.params.id}?_method=PUT`,
          this.eventData,
        )
        .then(() => {
          this.successfulOperationAlert('Event is updated successfully')
        })
    },
    getMaxSessionEndDate() {
      const endDatesArray = this.eventData.sessions.map(session => new Date(session.session_end))
      const maxEndDate = Math.max.apply(null, endDatesArray)
      this.maxEndDate = new Date(maxEndDate)
    },
  },
}
</script>
<style lang="scss"></style>
