<template>
  <div class="pt-2">
    <b-row
      align-h="end"
      class="mb-2"
    >
      <b-col
        md="2"
        class="mt-2 mt-md-0"
      >
        <b-button
          v-if="!isDatePassed"
          variant="primary"
          class="mr-2"
          @click="showAddSessionFormAndHideEditForm()"
        >
          <span class="text-nowrap">Add New Session</span>
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <b-table
        ref="table"
        :no-provider-sorting="true"
        show-empty
        empty-text="There is no Sessions for this Event!"
        :items="sessionsList"
        :fields="sessionsTableColumns"
        aria-busy="true"
        class="position-relative"
        responsive
        primary-key="id"
      >
        <template #cell(session_start)="{item}">
          <div class="text-nowrap">
            {{ item.session_start }}
          </div>
        </template>
        <template #cell(session_end)="{item}">
          <div class="text-nowrap">
            {{ item.session_end }}
          </div>
        </template>
        <template #cell(session_status)="{item}">
          <b-form-checkbox
            v-model="item.session_status"
            type="checkbox"
            value="active"
            unchecked-value="inactive"
            @change="updateSessionStatus(item.id)"
          />
        </template>
        <template #cell(required_vaccination)="{item}">
          <b-form-checkbox
            v-model="item.required_vaccination"
            type="checkbox"
            :value="1"
            :unchecked-value="0"
            disabled
          />
        </template>
        <template #cell(target_gender)="{item}">
          <span class="text-capitalize">{{ item.session_target_gender }}</span>
        </template>
        <template #cell(actions)="{item}">
          <b-dropdown
            v-if="!isDatePassed"
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="showEditSessionFormAndHideAddForm(item)"
            >
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>
            <b-dropdown-item @click="duplicateSession(item)">
              <feather-icon
                icon="CopyIcon"
              />
              <span> Duplicate </span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteSession(deleteEndPoint,item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import session from '@/common/compositions/PrayerBookings/duplicateSession'
import sessionStatus from '@/common/compositions/PrayerBookings/updateSessionStatus'
import deleteEntityComposition from '@/common/compositions/common/deleteRecord'

export default {
  name: 'SessionsTable',
  props: {
    sessionsList: { type: Array, default: () => [] },
    isDatePassed: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const { updateSessionStatus } = sessionStatus()
    const { deleteRecord, table } = deleteEntityComposition()
    const { duplicateSessionPromise } = session()
    const duplicateSession = sessionData => {
      duplicateSessionPromise(sessionData).then(() => {
        emit('updateSessionsList')
      })
    }
    return {
      updateSessionStatus,
      duplicateSession,
      deleteRecord,
      table,
    }
  },
  data() {
    return {
      sessionsTableColumns: [
        { key: 'topic', label: 'Topic' },
        { key: 'action_type', formatter: v => v.replace('_', ' ') },
        { key: 'session_start', label: 'Start' },
        { key: 'session_end', label: 'End' },
        { key: 'session_languages', label: 'Languages' },
        { key: 'session_status', label: 'Status' },
        { key: 'target_gender', label: 'Audience Gender' },
        { key: 'required_vaccination', label: 'Required Vaccination' },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/delete_session/`,
    }
  },
  methods: {
    deleteSession(deleteEndPoint, id) {
      this.deleteRecord(deleteEndPoint, id).then(() => {
        this.updateSessionList()
      })
    },
    updateSessionList() {
      this.$emit('updateSessionsList')
    },
    showAddSessionFormAndHideEditForm() {
      this.$emit('update:isAddSessionFormVisible', true)
      this.$emit('update:isEditSessionFormVisible', false)
    },
    showEditSessionFormAndHideAddForm(sessionData) {
      this.$emit('update:selected-session-to-edit', {
        ...sessionData,
        address: sessionData.address ? {
          ...sessionData.address,
          lat: sessionData.address?.location?.coordinates[1],
          lng: sessionData.address?.location?.coordinates[0],
        } : {},
      })

      this.$emit('update:isEditSessionFormVisible', true)
      this.$emit('update:isAddSessionFormVisible', false)
    },
  },
}
</script>
<style lang="scss">

</style>
